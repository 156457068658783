<template>
  <div>
    <!-- v-if="!Loading" -->

    <v-col cols="12" align="right">

      <!-- <v-btn icon fab style="float: left;" @click="goTab()" color="blue">
          <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn> -->

      <h1 style="float: left;margin-left:10px">Gestión de usuarios y permisos</h1>
      
      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'usuarios' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'usuarios'">
        Usuarios
      </v-btn>


      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'roles' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'roles'">
        Roles
      </v-btn>

      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'permisos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'permisos'">
        Permisos
      </v-btn>

      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'modulos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'modulos'">
        Módulos
      </v-btn>

      

      
    </v-col>

    <div style="background-color:#F5F5F5; padding:3px; margin-left:15px;margin-right:15px">
    </div>

    <div style="padding: 20px; margin: 10px; border-radius: 10px;">

      <Modulos v-if="tap_btn == 'modulos'" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <Permisos v-if="tap_btn == 'permisos'" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <Roles v-if="tap_btn == 'roles'" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <Usuarios v-if="tap_btn == 'usuarios'" :showAlert="showAlert" :hasPermission="hasPermission"/>

      <!-- <detalles v-if="tap_btn == 'detalles'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <levantamientos  v-if="tap_btn == 'levantamientos'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <servicios  v-if="tap_btn == 'servicios'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/> -->
      <!-- dos meses. -->
      <!-- <CheckList  v-if="tap_btn == 'checklist'" :visita="visita"/> -->
      <!-- <DetallesUnidad  v-if="tap_btn == 'unidad'" :visita="visita"/> -->
    </div>
  </div>
  <!-- v-if="!Loading" -->
  <!-- <ScreenSplash v-else /> -->
</template>

<script>

    import ScreenSplash from '@/components/ScreenSplash.vue';
    // import detalles from "./Visita/detalles.vue";
    // import levantamientos from "./Visita/levantamientos.vue";
    // import servicios from "./Visita/servicios.vue";

    import Modulos from "./ModulosComponent.vue";
    import Permisos from "./PermisosComponent.vue";
    import Roles from "./RolesComponent.vue";
    import Usuarios from "./UsuariosComponent.vue";

    import { mapState } from "vuex";

  export default {
    components: {
        ScreenSplash,
        Modulos,
        Permisos,
        Roles,
        Usuarios
        // detalles,
        // levantamientos,
        // servicios
    },
    setup() { },
    computed: {
      ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
      ...mapState(['router']),
      screenWidth() {
        return window.innerWidth;
      }
    },

    props: {
      id_visita: String,
      showAlert: Function,
      hasPermission: Function
    },
    data() {
      return {

        Loading: false,
        tap_btn: "usuarios",
        visita:{},
        creator:false,
      };
    },
  
    async created() {
        // this.Loading = true;
        // await this.$axios.post('visitas.consulta', {id_visita:this.id_visita}).then( (r) => {
        //     this.visita = r.data[0];
        //     this.creator = this.hasPermission('agenda_filter_admin') || this.visita.id_usuario_responsable == this.user.id_usr 
        // } ).catch( (error) => {
        //     console.log(error)
        // } ).finally( () => {
        //     this.Loading = false;
        // } );
    },

    mounted(){
        // this.Loading = false;
    },
  
  
  
    methods: {
  
        goTab(){
            //mandar el params para el tab de lista visitas.
            this.$router.push({name:'VisitasComponent'});
        }
    },
  };
  </script>