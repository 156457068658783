<template>
    <div v-if="!Loading" >

       <!--  {{ detalles_os.nombre_tipo_unidad }} -->
        <v-col cols="12" align="right">
            <!-- this.$router.go(-1); -->
            <v-btn icon fab style="float: left;" @click="this.$router.go(-1);" color="blue">
                <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>

            <h1 style="float: left;">N° Orden de Servicio: {{ ot }}</h1>
            <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
                :variant="tap_btn == 'caratula' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'caratula'">CARATURLA
            </v-btn>
            <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
                :variant="tap_btn == 'diagnostico_final' ? 'outlined' : 'text'" size="large"
                @click="tap_btn = 'diagnostico_final'">DIAGNOSTICO FINAL</v-btn>
            <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
                :variant="tap_btn == 'check_list' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'check_list'">CHECK
                LIST</v-btn>
            <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
                :variant="tap_btn == 'aviso_alerta' ? 'outlined' : 'text'" size="large"
                @click="tap_btn = 'aviso_alerta'">AVISO DE ALERTA</v-btn>


                <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
                :variant="tap_btn == 'cotizacion' ? 'outlined' : 'text'" size="large"
                @click="tap_btn = 'cotizacion'">PIEZAS DAÑADAS</v-btn>

                <v-btn v-show="detalles_os.nombre_tipo_unidad=='POLIPASTO NEUMATICO'" :block="screenWidth <= 500 ? true : false" elevation="0"
                :variant="tap_btn == 'check_list_neumatico' ? 'outlined' : 'text'" size="large"
                @click="tap_btn = 'check_list_neumatico'">CHECK LIST NEUMATICO</v-btn>
        </v-col>

        <br />

        <v-row justify="center">
            <v-col cols="12" lg="10" xl="8" v-show="tap_btn == 'caratula'">
                <CaratulaComponent :detalles="detalles_os" :tecnicos="tecnicos" 
                :showAlert="showAlert"
                :hasPermission="hasPermission"
                :appconf="appconf"/>
            </v-col>
            <v-col cols="12" lg="10" xl="8" v-show="tap_btn == 'diagnostico_final'">
                <DiagnosticoFinalComponent :detalles_os="detalles_os" :showAlert="showAlert"
                :hasPermission="hasPermission"
                :appconf="appconf" />
            </v-col>
            <v-col cols="12" lg="11" xl="8" v-show="tap_btn == 'check_list'">
                <CheckListComponent :detalles_os="detalles_os" :showAlert="showAlert"
                :hasPermission="hasPermission"
                :appconf="appconf" />
            </v-col>

            <v-col cols="12" lg="11" xl="8" v-show="tap_btn == 'check_list_neumatico'">
                <CheckListNeumaticoComponent :detalles_os="detalles_os" :showAlert="showAlert"
                :hasPermission="hasPermission"
                :appconf="appconf" />
            </v-col>

            <v-col cols="12" lg="11" xl="8" v-show="tap_btn == 'aviso_alerta'">
                <AvisoAlertaComponent :detalles_os="detalles_os" :showAlert="showAlert"
                :hasPermission="hasPermission"
                :appconf="appconf" />
            </v-col>

            <v-col cols="12" lg="11" xl="8" v-show="tap_btn == 'cotizacion'">
                <div class="card_contenedor">
                    <div class="center_div">
                        <span class="title_card_2">PRODUCTOS/SERVICIOS REQUERIDOS</span>
                    </div>

                    <v-sheet class="pa-4">
                        <v-row no-gutters>
                            <v-col cols="12" md="12">
                                <v-card elevation="0" class="col-lg-12 mt-5">
                                <v-card-actions>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Buscar concepto"
                                        single-line
                                        hide-details
                                        outlined
                                    ></v-text-field>
                                    <v-spacer></v-spacer>
<!-- hasPermission('ot_solicitar_cot') -->
                                    <v-btn 
                                    v-if="detalles_os.estatus_cot == null && hasPermission('ot_cot_additem')"
                                    style="float: right"
                                    color="green darken-4"
                                    small
                                    fab
                                    class="white--text"
                                    @click="modalConcepto = true"
                                    ><v-icon>mdi-plus</v-icon> Concepto
                                    </v-btn>

                                </v-card-actions>

                                <v-card-body>
                                <v-data-table 
                                    v-model:items-per-page="itemsPerPage"
                                    :headers="cotizacion_headers"
                                    :items="cotizacion_items"
                                    :search="search"
                                    fixed-header
                                    :loading ="cotizacion_loading"
                                    loading-text="Cargando... Por favor espere."
                                    no-data-text="Aún no hay conceptos agregados"
                                    dense
                                >
                                    <template v-slot:item="{ item, index }">
                                    <tr>
                                        <td>
                                        <v-text-field v-if="detalles_os.estatus_cot == null && item.selectable.id_prodserv == null && hasPermission('ot_cot_updateitem')" @input="
                                        updateItem(item.selectable, 'concepto')" :append-inner-icon="item.selectable.concepto_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.concepto" label="Concepto" density="compact" variant="outlined"></v-text-field>
                                        <span v-else>{{ item.selectable.concepto }}</span> 
                                        </td>

                                        <td>
                                        <v-textarea v-if="detalles_os.estatus_cot == null && hasPermission('ot_cot_updateitem')" @input="
                                        updateItem(item.selectable, 'descripcion')" :append-inner-icon="item.selectable.descripcion_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.descripcion" label="Descripción" density="compact" variant="outlined"></v-textarea>
                                        <span v-else>{{ item.selectable.descripcion }}</span> 
                                        </td>
                                        

                                        <td>
                                        <v-text-field v-if="detalles_os.estatus_cot == null && hasPermission('ot_cot_updateitem') && item.selectable.prodserv == 'producto'" type="number" @input="
                                        updateItem(item.selectable, 'cantidad')" :append-inner-icon="item.selectable.cantidad_spin ? 'mdi-spin mdi-loading' : ''" style="margin-top:15px"  v-model="item.selectable.cantidad" label="Cantidad" density="compact" variant="outlined"></v-text-field>
                                        <span v-else>{{ item.selectable.cantidad }}</span>
                                        </td>

                                        <td>
                                            {{ item.selectable.unidad }}
                                        <!-- <v-select v-if="detalles_os.estatus_cot == null && item.selectable.prodserv == 'producto'" @input="
                                        updateItem(item.selectable, 'unidad')" :append-inner-icon="item.selectable.unidad_spin ? 'mdi-spin mdi-loading' : ''"  style="margin-top:15px" v-model="item.selectable.unidad" label="Unidad" density="compact" variant="outlined" :items="['Pieza', 'Servicio']"></v-select>
                                        <span v-else>{{ item.selectable.unidad }}</span> -->
                                        </td>
                                        
                                        <td>
                                        <v-btn v-if="detalles_os.estatus_cot == null && hasPermission('ot_cot_deleteitem')" color="red" class="ma-1"  small dark  @click="item.selectable.deleting=true; deleteItem(item.selectable)" :disabled="item.selectable.deleting" :loading="item.selectable.deleting">
                                            <v-icon> mdi-delete </v-icon>
                                        </v-btn> 
                                        </td>
                                    </tr>

                                    </template>
                                
                                

                                </v-data-table>

                                <!-- boton que diga mandar a solicitar cotización -->
                                <!-- si tienes el permiso, estatus_cot == null y cotizacion_items.length > 0 -->
                                </v-card-body>

                                <v-card-actions v-if="hasPermission('ot_solicitar_cot') && detalles_os.estatus_cot == null && cotizacion_items.length > 0">
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn
                                        variant="text"
                                        color="secondary"
                                    >
                                        Cancel
                                    </v-btn> -->
                                    <v-btn
                                        variant="text"
                                        color="blue"
                                        :disabled="cotizacion_loading"
                                        :loading="cotizacion_loading"
                                        @click="solicitarCotizacion()"
                                    >
                                        Solicitar cotización
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </div>
            </v-col>

            
            

            

        </v-row>


    </div>
    <ScreenSplash v-else />

    <v-dialog persistent v-model="modalConcepto" width="700px" >	
        <v-card class="pa-5">
            <Modal  @agregarItem="agregarItem" :id_ot="detalles_os.id_ot"  @modal="modalConcepto = $event"/>
        </v-card>
    </v-dialog>


</template>
<script>
import CaratulaComponent from "./components/CaratulaComponent.vue";
import DiagnosticoFinalComponent from "./components/DiagnosticoFinalComponent.vue";
import CheckListComponent from "./components/CheckListComponent.vue";
import AvisoAlertaComponent from "./components/AvisoAlertaComponent.vue";
import CheckListNeumaticoComponent from "./components/CheckListNeumatico.vue";
import Modal from './components/ConceptoModal.vue';
import ScreenSplash from '@/components/ScreenSplash.vue';
import { mapState, mapMutations } from "vuex";

export default {
    components: {
        CaratulaComponent,
        DiagnosticoFinalComponent,
        CheckListComponent,
        AvisoAlertaComponent,
        CheckListNeumaticoComponent,
        ScreenSplash,
        Modal
    },
    props: {
        showAlert: Function, 
        hasPermission: Function,
        appconf:Object
    },
    computed: {
        ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
    },
    data() {
        return {
            vista: "vista",
            ot: this.$route.params.id_ot,
            tap_btn: 'caratula',
            detalles_os:{},
            tecnicos:[],
            Loading:true,
            search:'',
            itemsPerPage:100,
            cotizacion_headers:[
                    { title: 'Producto o servicio'	 , align: 'left'  , key: 'concepto' 	},
                    { title: 'Descripción'	 , align: 'left'  , key: 'descripcion' 	},
                    { title: 'Cantidad'  					 , align: 'left'  , key: 'cantidad' },
                    { title: 'Unidad'  					 , align: 'left'  , key: 'unidad' },
                    // { title: 'Precio unit.'	 , align: 'left'  , key: 'precio_unitario' },
                    // { title: 'Precio Total'	 , align: 'left'  , key: 'total' },
                    { title: ''	 , align: 'left'  , key: 'action' }
                ],
            cotizacion_items:[],
            cotizacion_loading:false,
            modalConcepto:false
        }
    },
    mounted() {
        this.GetOrdenDeServicio()
    },
    methods: {

        async solicitarCotizacion(){
            this.cotizacion_loading = true;
            if(window.confirm("¿Éstas seguro que desea solicitar esta cotización?")){
                await this.$axios.post('ordenes.servicio.cotizacion', {ot:this.detalles_os, id_usr:this.user.id_usr}).then(()=>{
                    window.showAlert({
                        activo: true,
                        texto: "Cotización solicitada con éxito!",
                        color: "success",
                        vertical: true
                    });
                    this.detalles_os.estatus_cot = 0;
                }).catch(e=>{
                    window.showAlert({
                        activo: true,
                        texto: "Lo sentimos! Algo salio mal...",
                        color: "error",
                        vertical: true
                    });
                }).finally( () => {
                    this.cotizacion_loading = false;
                });
            } else {
                this.cotizacion_loading = false;
            }
        },


        agregarItem(item){
            this.cotizacion_items.push(item);
        },

        async deleteItem(item){
            if(window.confirm("¿Éstas seguro que desea eliminar este concepto '"+item.concepto+"'?")){
                await this.$axios.post('ordenes.servicio.deleteItem', item).then(()=>{
                     this.cotizacion_items.splice(this.cotizacion_items.indexOf(item), 1);
                }).catch(e=>{
                    window.showAlert({
                        activo: true,
                        texto: "Lo sentimos! Algo salio mal...",
                        color: "error",
                        vertical: true
                    });
                }).finally( () => {
                    item.deleting = false;
                });
            } else {
                item.deleting = false;
            }
        },
        // this.cotizacion_items.push(item);
        async updateItem(item, column){
            if(item.timer != null){
                clearTimeout(item.timer);
            }

            item.timer = setTimeout(async() => {
            if(column == 'cantidad'){
                item.cantidad_spin=true;
            } else if(column == 'concepto'){
                item.concepto_spin=true;
            } else if(column == 'descripcion'){
                item.descripcion_spin=true;
            }
            
            await this.$axios.post('ordenes.servicio.updateItem', item).then(()=>{
            
            }).catch(e=>{
                window.showAlert({
                activo: true,
                texto: "Lo sentimos! Algo salio mal...",
                color: "error",
                vertical: true
                });
            }).finally( () => {

                if(column == 'cantidad'){
                    item.cantidad_spin=false;
                } else if(column == 'concepto'){
                    item.concepto_spin=false;
                }  else if(column == 'descripcion'){
                    item.descripcion_spin=false;
                }
            });
            }, 1000);

        },


        GetOrdenDeServicio() {
            let datapost = {
                id_ot: this.ot
            }
            this.Loading = true;
            this.$axios.post('obtener.detalles.orden.servicio', datapost)
            .then(r => { 

                this.detalles_os=r.data.ot;
                this.cotizacion_items = r.data.ot_items;
                this.tecnicos =r.data.usuarios;
                // console.log(r) 
                
            }).catch(e => { 

                console.log(e)

            }).finally( () => {

                this.Loading = false;

            } )
        }
    }
}
</script>

<style scoped>
.card_contenedor {

  margin-top: 20px;
  border: solid 2px #000;
  width: 100%;
  border-radius: 5px;
}

.title_card {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  letter-spacing: 2px;
  background-color: #FFD600;
}


.center_div {

  width: 100%;
  top: -25px;
  display: flex;
  justify-content: center;
}

.center_element {
  display: flex;
  justify-content: center
}


.color_letter {
  color: #000000;
  padding: 4px;
  background-color: #FFD600;
}

.color_spacer {
  color: #000000;
  padding: 4px;

}

.space_container {
  margin-top: -20px;
}

.mg_tn {
  margin-top: -30px;
}

.nota_contenedor {
  padding: 10px;
  border: solid 2px #000000;
  font-size: 18px;
  color: #5F5F5F;
  font-weight: bold;
}

.title_card_2 {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
  padding-top: 5px;
  letter-spacing: 2px;
  background-color: #0D3961;
  color: #fff
}

.imagen-opaca {
opacity: 0.2; 
}
</style>