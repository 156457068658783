<template>
  <div>
      <v-row class="pa-0 ma-3" justify="center">
        <v-col cols="12">
          <v-card-actions class="font-weight-black headline"> Permisos </v-card-actions>
          <v-card class="mt-3" outlined>
            <v-card-actions>
              <v-text-field v-model="search" append-icon="search" label="Buscar permiso" single-line hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn small color="green" dark @click="data = {}; modal = true">Agregar </v-btn>
              <v-btn small class="gray" icon dark @click="consulta()"><v-icon>mdi-refresh</v-icon> </v-btn>
            </v-card-actions>
          
            <v-data-table 
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items="items"
              :search="search"
              fixed-header
              hide-default-footer
              :loading ="Loading"
              loading-text="Cargando... Por favor espere."
              no-data-text="Aún no hay permisos registrados"
              dense
            >
              <template v-slot:item.action="{ item }" > 
                <v-btn  small text color="warning" dark @click="data = item.selectable; modal = true"><v-icon> mdi-pencil </v-icon> </v-btn> 
              </template>
  
              <template v-slot:item.estatus="{ item }" > 
                
              </template>
            </v-data-table>
  
          </v-card>
          
           <v-dialog persistent v-model="modal" width="700px" >	
            <v-card class="pa-5">
              <Modal :item="data" :showAlert="showAlert" :consulta="consulta" @modal="modal = $event"/>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
    import Modal from './PermisosModal.vue';
    import axios from 'axios'
  
    export default {
      props: {
        showAlert: Function
      },
      components: {
        Modal
      },
      data () {
          return {
            tableName:'permisos',
            search: '',
            itemsPerPage: 100,
            items:[],
            data:{},
            Loading:false,
            modal: false,
            headers:[
              { title: 'Módulo'  					 , align: 'left'  , key: 'id_modulo' },
              { title: 'Clave permiso'	 , align: 'left'  , key: 'key_permiso' },
              { title: 'Descripción'			 , align: 'left'  , key: 'desc_permiso' 	},
              { title: ''  			 , align: 'right' , key: 'action', sortable: false },
  
            ],
          }
        },
  
        async created(){
          await this.consulta();
        },
  
        computed:{
        },
  
        methods:{
          async consulta(){
            this.Loading=true;
            axios.post('datatable.search', {table:this.tableName}).then((res)=>{
              this.items = res.data;
            }).catch((err)=>{
              console.log(err);
            }).finally( ()=> {
              this.Loading=false;
            });
          },
        }
    }
  </script>
  