<template>
  <div>
    <!-- v-if="!Loading" -->

    <v-col cols="12" align="right">

      <!-- <v-btn icon fab style="float: left;" @click="goTab()" color="blue">
          <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn> -->

      <h1 style="float: left;margin-left:10px">Catálogos</h1>
      
      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'tipo_unidades' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'tipo_unidades'">
        Tipo unidades
      </v-btn>


      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'servicios' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'servicios'">
        Servicios
      </v-btn>



      
    </v-col>

    <div style="background-color:#F5F5F5; padding:3px; margin-left:15px;margin-right:15px">
    </div>

    <div style="padding: 20px; margin: 10px; border-radius: 10px;">
      <Servicios v-if="tap_btn == 'servicios'" :showAlert="showAlert" :hasPermission="hasPermission" />
      <TipoUnidades v-if="tap_btn == 'tipo_unidades'" :showAlert="showAlert" :hasPermission="hasPermission" />
 
      <!-- <detalles v-if="tap_btn == 'detalles'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <levantamientos  v-if="tap_btn == 'levantamientos'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <servicios  v-if="tap_btn == 'servicios'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/> -->
      <!-- dos meses. -->
      <!-- <CheckList  v-if="tap_btn == 'checklist'" :visita="visita"/> -->
      <!-- <DetallesUnidad  v-if="tap_btn == 'unidad'" :visita="visita"/> -->
    </div>
  </div>
  <!-- v-if="!Loading" -->
  <!-- <ScreenSplash v-else /> -->
</template>

<script>

    import ScreenSplash from '@/components/ScreenSplash.vue';
    import Servicios from "./ServiciosComponent.vue";
    import TipoUnidades from "./TipoUnidadesComponent.vue";
    // import levantamientos from "./Visita/levantamientos.vue";
    // import servicios from "./Visita/servicios.vue";

    import { mapState } from "vuex";

  export default {
    components: {
        ScreenSplash,
        Servicios,
        TipoUnidades
    },
    setup() { },
    computed: {
      ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
      ...mapState(['router']),
      screenWidth() {
        return window.innerWidth;
      }
    },

    props: {
      id_visita: String,
      showAlert: Function,
      hasPermission: Function
    },
    data() {
      return {

        Loading: false,
        tap_btn: "tipo_unidades",
        visita:{},
        creator:false,
      };
    },
  
    async created() {
        // this.Loading = true;
        // await this.$axios.post('visitas.consulta', {id_visita:this.id_visita}).then( (r) => {
        //     this.visita = r.data[0];
        //     this.creator = this.hasPermission('agenda_filter_admin') || this.visita.id_usuario_responsable == this.user.id_usr 
        // } ).catch( (error) => {
        //     console.log(error)
        // } ).finally( () => {
        //     this.Loading = false;
        // } );
    },

    mounted(){
        // this.Loading = false;
    },
  
  
  
    methods: {
  
        goTab(){
            //mandar el params para el tab de lista visitas.
            this.$router.push({name:'VisitasComponent'});
        }
    },
  };
  </script>