<template>
  <v-content class="pa-0">
		<v-row justify="center" no-gutters>
			<v-col cols="12">

				<v-card-actions class="pa-0" >
					<h3> <strong> {{ nuevoRegistro ? 'NUEVO TIPO DE UNIDAD':'ACTUALIZAR DETALLES DEL TIPO DE UNIDAD' }}  </strong></h3> 
					<v-spacer></v-spacer>
					<v-btn color="error" fab small @click="$emit('modal',false)" ><v-icon>mdi-close</v-icon></v-btn>
				</v-card-actions>

				<v-row class="mt-3">

					<v-col cols="12" >
						<v-text-field
							v-model="item.tipo_unidad_nombre" label="Nombre (Tipo de unidad)" placeholder="Tipo de unidad" append-icon="person"
							hide-details dense filled
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<v-text-field
							v-model="item.descripcion_servicio" label="Descripción" placeholder="Descripción dle tipo de unidad" append-icon="domain"
							hide-details dense filled 
						></v-text-field>
					</v-col>
				</v-row>

				<v-card-actions class="pa-0 mt-3">
					<v-spacer></v-spacer>
					 <v-btn  
					 	:disabled="saving" 
						 persistent 
						 :loading="saving"
						 dark center 
						 class="white--text" 
						 color="blue" 
						 @click="updcreated()">
             {{  nuevoRegistro ? 'AGREGAR TIPO DE UNIDAD' : 'GUARDAR CAMBIOS' }}
          </v-btn> 
				</v-card-actions>

			</v-col>
		</v-row>
	</v-content >
</template>

<script>
export default {
  
  setup(){  },
  computed: {  },
  components: { },

  props: {
    item: Object,
    showAlert: Function,
    consulta: Function
  },

  data() {
    return {
      nuevoRegistro: Object.keys(this.item).length === 0 ? true : false,
      saving:false
    };
  },

  created() {
    // if(!this.nuevoRegistro){
        // this.cliente = {...this.cliente, key_role:'cliente'};
        // const cliente = new Proxy(this.cliente, {
        //   get(target, property) {
        //     // Implementa el comportamiento deseado para acceder a la propiedad
        //     if (property in target) {
        //       return target[property]; // Devuelve el valor de la propiedad si existe
        //     } else {
        //       return undefined; // Devuelve undefined si la propiedad no existe
        //     }
        //   },
        //   set(target, property, value) {
        //     target[property] = value;
        //     return true;
        //   }
        // });

        // console.log(typeof cliente.key_role)
      // }
  },

  mounted() {

  },
  
  methods: {
    async updcreated() {

      var me = this;
      this.saving = true;
      let payload = {
        table:'tipo_unidades',
        foreignkey:'id_tipo_unidad',
        protectedkeys:['id_tipo_unidad','tipo_unidad_estatus'],
        data: this.item
      }
      await this.$axios
        .post("datatable.updcreated", payload)
        .then((r) => {
          //alert...and reload table of 

          this.showAlert({
            activo: true, 
            texto: this.nuevoRegistro ? "Servicio agregado con éxito!" : "Datos actualizados con éxito!", 
            color: "success", 
            vertical: true
          });

          if(this.nuevoRegistro){
            this.consulta();
          }

          me.$emit('modal',false);

        })
        .catch((e) => {
          console.log(e);
          this.showAlert({
            activo: true, 
            texto:"Lo sentimos! Algo salio mal...", 
            color: "error", 
            vertical: true
          });
        }).finally( () => {
          this.saving = false;
          //close modal?
        } );
    },
  },
};
</script>